import axios from "axios";
import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { FaUserEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { SubTitle } from "../../Components/CustomComponents";
import DeleteModal from "../../Components/Modals/DeleteModal";
import EditUserModal from "../../Components/Modals/EditUserModal";
import Table from "../../Components/Table/Table";
import Toast from "../../Hooks/useToast";
import styles from "./styles/user_management.module.scss";
// import { ToastContainer, toast } from "react-toastify";

//
import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux
import { updateUsers } from "../../Data/features/userSlice";
import { useGetAllUsersQuery } from "../../Data/services/userApi"; // get data from API Servers

const UserManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  // const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetAllUsersQuery({ refetchOnMountOrArgChange: true });

  var userData = useSelector((state) => state.user.users);
  // console.log(`userData = ${userData}`);
  useEffect(() => {
    setRowData(userData);
  }, [userData]);

  useEffect(() => {
    if (data && isSuccess) {
      // console.log("isSuccess = " + data.users);
      dispatch(updateUsers(data.users));
    }
  }, [isSuccess, data, dispatch]);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteModal(false);
    setEditedData("");
  };

  const deleteData = (id) => {
    console.log(id);
    axios(`https://jsonplaceholder.typicode.com//posts/${id}`)
      .then((res) => {
        // console.log(res.data)
        // console.log(res.status)
        // setData(res.data);
        if (res.status === 200) {
          Toast("Deleted Successfully", "success");
          handleDeleteModalClose();
        }
      })
      .catch((e) => {
        console.log(e);
        console.log(e.message);
        Toast("Something went wrong.", "error");
        // handleDeleteModalClose();
        // setIndicationMessage("Something went wrong, refresh again");
      });
  };

  const handleEditModalOpen = () => {
    setEditModal(true);
  };

  const handleEditModalClose = () => {
    setEditModal(false);
    setEditedData("");
  };

  const handleAddUser = () => {
    setEditModal(true);
  };

  // useEffect(() => {
  //   getData();
  //   return () => { };
  // }, []);

  // const getData = () => {
  //   // axios('https://jsonplaceholder.typicode.com/comments').then((res) => {
  //   axios("https://jsonplaceholder.typicode.com/users")
  //     .then((res) => {
  //       // console.log(res.data)
  //       // console.log(res.status)
  //       setIndicationMessage("");

  //       setData(res.data);

  //       setData((prv) => [...prv, res.data]); // todo:

  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       setIndicationMessage("Something went wrong, refresh again");
  //     });
  // };

  function indication() {
    // return something here
    // return 'Table is empty';
    return indicationMessage;
  }

  const columns = [
    {
      dataField: "username",
      text: "Name",
      sort: true,
      headerStyle: {
        width: "200px",
      },
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      headerStyle: {
        width: "300px",
      },
    },
    {
      dataField: "role",
      text: "Roles",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        if (row.id === 1) return <span> SuperAdmin </span>;
        if (row.id === 2) return <span>Admin</span>;
        if (row.id >= 3) return <span>Moderator</span>;
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        if (row.id === 1) return <div className={styles.active}> Active </div>;
        if (row.id >= 2) return <div className={styles.block}>Block</div>;
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.id) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              <div
                className={`${styles.btnBg} d-flex justify-content-center align-items-center rounded-3 mx-2 p-2 text-white`}
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleEditModalOpen(true);
                }}
              >
                <span
                  className={`align-items-center d-flex justify-content-center pe-1  `}
                >
                  <FaUserEdit />
                </span>
                <span>Edit</span>
              </div>
              <div
                className="d-flex justify-content-center align-items-center bg-danger rounded-3 p-2 mx-2 text-white "
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleDeleteModalOpen();
                }}
              >
                <span className={`d-flex align-items-center pe-1`}>
                  <MdDeleteForever />
                </span>
                <span>Delete</span>
              </div>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {deleteModal ? (
        <DeleteModal
          handleDeleteModalClose={handleDeleteModalClose}
          data={editedData}
          onDelete={deleteData}
        />
      ) : (
        ""
      )}

      {editModal ? (
        <EditUserModal
          handleEditModalClose={handleEditModalClose}
          data={editedData}
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"User Management"} classes="text-black" />
        </div>
        {
          // <Row>
          //   <Col md={12}>
          //     <Button
          //       classes={`${styles.cardBtn} `}
          //       text="Add User"
          //       onClick={handleAddUser}
          //     ></Button>
          //   </Col>
          // </Row>
        }
      </div>

      <div className={styles.mainTable}>
        {<Table data={rowData} columns={columns} indication={indication} />}
      </div>
    </div>
  );
};

export default UserManagementScreen;
