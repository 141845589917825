import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CustomInput,
  Description,
  SubTitle,
} from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { WEB_ROUTES } from "../../constants";
import { storeToken } from "../../Data/services/localStorageService";
import { ACCOUNT_ENUM } from "./Route";

export default function Login({ setPage }) {
  const { handleSubmit, control, register } = useForm();
  const navigate = useNavigate();
  // const pagename = useSelector((state) => state.pagename.pagename);

  const handleForgotPasswordClick = () => {
    setPage(ACCOUNT_ENUM.FORGOT_PASSWORD);
  };

  const handleLogin = async (data) => {
    data["device_type"] = "234";
    data["device_token"] = "android";
    storeToken("token");
    navigate(WEB_ROUTES.DASHBOARD);
    // try {
    //     // const response = await LoginAPI(data)
    //     // SetAuthUserLocalStorage(response?.data?.data)
    //     // SetTokenLocalStorage(response?.data?.data?.api_token)
    //     navigate('dashboard')
    //     // Toast(response?.data?.message, "success");
    // } catch (error) {
    //     if (error?.response && error?.response?.data?.message) {
    //         Toast(error?.response?.data?.message, "error");
    //     } else {
    //         Toast("An error occurred during login", "error");
    //     }
    // }
  };

  return (
    <AccountLayout>
      {
        // <Title text="Welcome to" classes="mb-2 fs-4" />
      }
      <SubTitle text="Admin Panel" classes="mb-2 text-black" />

      <Form onSubmit={handleSubmit(handleLogin)}>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup
              className="mb-3"
              style={{
                width: "300px",
              }}
            >
              <CustomInput
                placeholder="Email"
                type="email"
                onChange={onChange}
              />
            </InputGroup>
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup
              className="mb-3 "
              style={{
                width: "300px",
              }}
            >
              <CustomInput
                type="password"
                placeholder="Password"
                onChange={onChange}
              />
            </InputGroup>
          )}
        />
        <Button
          text="Login"
          classes="mt-3 mb-2"
          type="submit"
          onClick={handleLogin}
        />
      </Form>
      <Description
        classes="ms-auto cursor-pointer text-black"
        text="Forgot Password ?"
        onClick={handleForgotPasswordClick}
      />
    </AccountLayout>
  );
}
