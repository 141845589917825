import React, { useState } from "react";
import { Col, InputGroup, Row } from "react-bootstrap";
import { Button, Input, SubTitle } from "../../Components/CustomComponents";
import styles from "./styles/sendNotificationScreen.module.scss";

const SendNotificationScreen = ({ setPage }) => {
  const [title, setTitle] = useState("");
  const [notification, setNotification] = useState("");

  const handleSendNotification = () => {
    console.log(title);
    console.log(notification);
  };

  return (
    <div className={styles.MainContainer}>
      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Send Notification"} classes="text-black" />
        </div>
        <Row>
          <Col md={12}>
            <Button
              classes={`${styles.cardBtn} `}
              text="Send"
              onClick={handleSendNotification}
            ></Button>
          </Col>
        </Row>
      </div>

      <div className="mt-3">
        <InputGroup className="mb-3">
          <Input
            placeholder="Title"
            type="text"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </InputGroup>

        <InputGroup className="mb-3">
          <Input
            placeholder="Notification"
            type="textarea"
            as="textarea"
            rows={5}
            value={notification}
            onChange={(e) => {
              setNotification(e.target.value);
            }}
          />
        </InputGroup>
      </div>
    </div>
  );
};

export default SendNotificationScreen;
