import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  Button,
  CustomInput,
  Description,
  Title,
} from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { ACCOUNT_ENUM } from "./Route";

export default function ForgotPassword({ setPage }) {
  const { handleSubmit, control, register } = useForm();
  const navigate = useNavigate();
  const isLoading = true;
  console.log(isLoading, "isLoading");
  const handleBackButtonClick = () => {
    setPage(ACCOUNT_ENUM.LOGIN);
  };

  const handleDataSubmit = async (userData) => {
    // try {
    //     // Use unwrap() to get the response data directly
    //     const response = await ForgetPassword(userData).unwrap();
    //     Toast(response?.message, "success");
    //     // dispatch(updateLogin(true))
    //     navigate('/account')
    // } catch (error) {
    //     console.log(error.data.message, 'errrrr');
    //     if (error.data && error.data.message) {
    //         Toast(error?.data?.message, "error");
    //     } else {
    //         Toast("An error occurred during login", "error");
    //     }
    // }
  };

  return (
    <AccountLayout
      hasBackButton={true}
      handleBackButton={handleBackButtonClick}
    >
      <Title text="Forgot Password" classes="mb-2 text-black" />
      <Description
        text="In order to reset your password you need to enter your registered email address."
        classes="text-center fs-7 text-black"
      />
      <Form onSubmit={handleSubmit(handleDataSubmit)}>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup
              className="mb-3"
              style={{
                width: "300px",
              }}
            >
              <CustomInput
                placeholder="Email"
                type="email"
                onChange={onChange}
                value={value}
              />
            </InputGroup>
          )}
        />
        <Button
          text="Continue"
          classes="mt-3 mb-2"
          disabled={isLoading}
          type="submit"
        />
      </Form>
    </AccountLayout>
  );
}
